<script>
export default {
  data() {
    return {
      sg_page: 1,
      sg_page_size: 10,
      sg_totalCount: 0,
      sg_firstText: 0,
      sg_secondText: 0,
      sg_filter: {},
      sg_filteredCategories: [],
      suggestionList: [],
      sg_categoriesList: [],
      sg_perPageOptions: [10, 25, 50, 100],
      sg_dateOptions: [
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This month",
        "This year",
        "Last year",
        "Custom",
      ],
    };
  },
  watch: {
    sg_filteredCategories(value) {
      this.sg_filter.sg_categories = value;
    },
  },
  computed: {
    sg_normalizedCategories() {
      return this.sg_categoriesList.length > 0
        ? this.sg_categoriesList.map((c) => this.normalizeCategory(c))
        : [];
    },
  },
  methods: {
    sg_resetData() {
      this.sg_page = 1;
      this.sg_page_size = 10;
      this.sg_totalCount = 0;
      this.sg_firstText = 0;
      this.sg_secondText = 0;
      this.sg_questionList = [];
      this.sg_filter = {
        sg_date: "",
        sg_search: "",
        sg_categories: 0,
      };
      this.sg_categoriesList = [];
    },

    sg_normalizeCategory(category) {
      const result = {
        id: category.id,
        label: category.title,
      };
      if (category.children && category.children instanceof Array) {
        result.children = category.children.map((c) =>
          this.normalizeCategory(c)
        );
      }
      return result;
    },
    sg_getCategory() {
      let params = {
        locale: this.parseJSON(localStorage.getItem("selectedCountry"))[
          "locale"
        ],
      };

      this.$apiService
        .getCategory(params)
        .then((response) => {
          let res = response.data.data;

          if (response.status == 200) {
            this.sg_categoriesList = res;
          }
        })
        .catch((e) => {
          this.sg_categoriesList = [];
        });
    },
    sg_dateChange() {
      if (this.sg_filter.sg_start_date) {
        delete this.sg_filter.sg_start_date;
      }

      if (this.sg_filter.sg_end_date) {
        delete this.sg_filter.sg_end_date;
      }
    },
  },
};
</script>
