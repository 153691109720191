<template>
  <div class="container">
    <div class="user-login-wrap row p-3">
      <div
        data-v-97462a74=""
        class="card-wrap col-md-7 mx-auto add-expertise-wrap"
      >
        <div class="card-heading">
          <h3>Doctor's Expertise</h3>
        </div>
        <!-- v-model="selected" -->
        <VSelect
          @input="setSelected"
          :options="expertiseList"
          :disabled="disabled"
          :searchable="searchable"
          search-placeholder="Search Bar"
          search-not-found="Record Not Found"
          default-title="Select Expertise"
          class="expert-drp"
        />
        <div class="mt-3">
          <ul class="expert-list">
            <li
              v-for="(expertise, index) in selected"
              :key="index"
            >
              <span>
                {{ expertise.text }} <span
                  @click="remove(index,expertise.id)"
                  class="removeBtn"
                > <img src="../assets/images/xmark-solid.svg"> </span>
              </span>
            </li>
          </ul>
          <!-- <strong>{{ selected }}</strong> -->
        </div>
        <hr class="guidelines-break">
        <div class="row">
          <div class="col-md-8">
            <!-- <p class="m-0">
                If you have any questions/comments/concerns:
                <br>
                <span style="color:#53bbb4">feedback@askdrfigo.org</span>
              </p> -->
          </div>
          <div class="col-md-4">
            <a
              class="drbtn btn btn-outline-primary"
              @click="onSubmit()"
              style="width: 100%"
            >Next</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VSelect from "vue-select-picker-bootstrap";
export default {
  name: "Expertise",
  components: {
    VSelect,
  },
  data() {
    return {
      profileInfo: {
        avatar: null,
      },
      copyProfileInfo: {},
      isChangePassword: false,
      defaultAvatar: null,
      expertiseList: [],
      disabled: false,
      searchable: true,
      selected: [],
    };
  },
  methods: {
    remove(index,id) {
      this.removeDoctorExpertise(id);
      this.$delete(this.selected,index);
      return true;
    },
    expertiseExists(name) {
     return this.selected.some(function (el,index) {
        if(el.value === name){
          return true;
        }else{
          return false;
        }
      });
    },
    setSelected(data) {
      // if not exit then remove
      if (!this.expertiseExists(data.value)) {
        // call insert api Here
        this.addDoctorExpertise(data);
      }
    },
    getProfile() {
      let loader = Vue.$loading.show();

      this.$apiService
        .getProfile()
        .then((response) => {
          loader.hide();

          let res = response.data.data;

          if (response.status == 200) {
            this.profileInfo = res;
            // get all expertise of doctor;
            let doctorId = res.id;
            this.getDoctorExpertise(doctorId);

            this.copyProfileInfo = this.parseJSON(
              JSON.stringify(this.profileInfo)
            );
          }
        })
        .catch((e) => {
          loader.hide();
          this.profileInfo = { avatar: null };
        });
    },
    getAllExpertise() {
      let loader = Vue.$loading.show();

      this.$apiService
        .getAllExpertise()
        .then((response) => {
          loader.hide();
          let res = response.data.data;
          this.expertiseList = res.map((val) => {
            return {
              value: val.id,
              text: val.expertise_name,
            };
          });
        })
        .catch((e) => {
          loader.hide();
        });
    },
    getDoctorExpertise(doctorId) {
      let loader = Vue.$loading.show();
      this.$apiService
        .getDoctorExpertise(doctorId)
        .then((response) => {
          loader.hide();
          let res = response.data.data;
          this.selected = res.expertise.map((val) => {
            return {
              value: val.expertise_id,
              text: val.expertise_name,
              id:val.pivot.id
            };
          });
        })
        .catch((e) => {
          loader.hide();
        });
    },
    removeDoctorExpertise(id) {
      let loader = Vue.$loading.show();

      this.$apiService
        .removeDoctorExpertise(id)
        .then((response) => {
          loader.hide();
         if (response.data.success) {
            this.$toastMessage.success(response.data.message);
            this.getAllExpertise();
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    addDoctorExpertise(data) {
      let loader = Vue.$loading.show();
      let sendData = {
        expertise_id: data.value,
        doctor_id: this.profileInfo.id
      };
      console.log(data);
      this.$apiService
        .addDoctorExpertise(sendData)
        .then((response) => {
          let res = response.data;
          let insertedId = res.data.id;
          if(insertedId > 0) {
            this.selected.push({
              value: data.value,
              text: data.text,
              id : insertedId
            });
          }
          loader.hide();
          if (response.data.success) {
            this.$toastMessage.success(res.message);
            this.getAllExpertise();
          }
        })
        .catch((e) => {
          loader.hide();
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
    onSubmit() {
        this.$router.push("/guidelines");
    },
  },
  created() {
    this.getProfile();
  },
  mounted() {
    this.getAllExpertise();
  }
};
</script>

<style scoped>
.guidelines-title {
  font-size: 18px;
  color: #53bbb4;
}

.guidelines-break {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-color: #53bbb4;
}

.add-expertise-wrap {
  padding: 25px;
}
</style>
