<template>
  <div class="container">
    <div class="user-login-wrap row p-3">
      <div class="card-wrap col-md-5 mx-auto add-question-wrap">
        <a class="navbar-brand text-center d-block mb-5 p-0"><img
          src="../assets/images/logo.png"
          width="150"
          alt="site-logo"
        ></a>
        <form action="">
          <div class="form-group mb-4">
            <label for="">Email</label>
            <input
              type="text"
              placeholder="Email"
              class="form-control"
              v-model="newUser.email"
            >
          </div>
          <div class="form-group">
            <a
              class="drbtn btn  btn-outline-primary"
              @click="onSubmit()"
              style="width:100%"
            >Forgot Password</a>
          </div>
        </form>
        <router-link
          to="/"
          class="forgotpass"
        >
          Log In
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Forgot",
  data() {
    return {
      newUser: {},
    };
  },
  methods: {
    onSubmit() {
      if (this.newUser.email == undefined || this.newUser.email == "") {
        this.$toastMessage.error("Email is required");
        return false;
      }

      if (!this.$apiService.validateEmail(this.newUser.email)) {
        this.$toastMessage.error("Please enter valid email");
        return false;
      }

      this.newUser.site_url = window.location.origin + "/reset-password";

      let loader = Vue.$loading.show();

      this.$apiService
        .forgotPassword(this.newUser)
        .then((response) => {
          loader.hide();
          let res = response.data;

          if (response.status == 200) {
            this.$toastMessage.success(res.message);
            this.$router.push("/");
          }
        })
        .catch((e) => {
          loader.hide();
          if (e.response.status === 404) {
            this.$toastMessage.error(e.response.data.message);
          }
        });
    },
  },
  created() {
    localStorage.clear();
  },
};
</script>
