import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login'
import Forgot from '../components/Forgot'
import Reset from '../components/Reset'
import CheckList from '../components/CheckList'
import Expertise from '../components/Expertise'
import Layout from '../views/Layout'
import Question from '../views/pages/Question'
import ClaimedQuestion from '../views/pages/ClaimedQuestion'
import AnsweredQuestion from '../views/pages/AnsweredQuestion'
import AllAnsweredQuestion from '../views/pages/AllAnsweredQuestion'
import Profile from '../views/pages/Profile'
import Answer from '../views/pages/Answer'
import Notification from '../views/pages/Notification'
import NotificationDetail from '../views/pages/NotificationDetail'
import FlagQuestion from '../views/pages/FlagQuestion'
import AllFlagQuestion from '../views/pages/AllFlagQuestion'

Vue.use(VueRouter)

function guardMyroute(to, from, next) {
  let isAuthenticated = false;
  if (localStorage.getItem('accessToken') && localStorage.getItem('userDetails'))
    isAuthenticated = true;
  else
    isAuthenticated = false;

  if (isAuthenticated) {
    next('/question');
  }
  else {
    next();
  }
}

function checkToken(to, from, next) {
  let isAuthenticated = false;
  if (localStorage.getItem('accessToken') && localStorage.getItem('userDetails'))
    isAuthenticated = true;
  else
    isAuthenticated = false;

  if (isAuthenticated) {
    next();
  }
  else {
    next('/');
  }
}

const routes = [
  {
    path: '/',
    name: 'Login',
    beforeEnter: guardMyroute,
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'Forgot',
    component: Forgot
  },
  {
    path: '/reset-password/:token',
    name: 'Reset',
    component: Reset
  },
  {
    path: '/guidelines',
    name: 'CheckList',
    component: CheckList,
    beforeEnter: checkToken
  },
  {
    path: '/expertise',
    name: 'Expertise',
    component: Expertise,
    beforeEnter: checkToken
  },
  {
    path: '',
    component: Layout,
    beforeEnter: checkToken,
    children: [
      {
        path: "/question",
        name: "Question",
        component: Question
      },
      {
        path: "question/:questionId/answer",
        name: "Answer",
        component: Answer
      },
      {
        path: "/claimed-question",
        name: "Claimed Question",
        component: ClaimedQuestion,
      },
      {
        path: "claimed-question/:questionId/answer",
        name: "Answer",
        component: Answer
      },
      {
        path: "/answered-question",
        name: "Answered Question",
        component: AnsweredQuestion
      },
      {
        path: "answered-question/:questionId/answer",
        name: "Answer",
        component: Answer
      },
      {
        path: "/all-answered-question",
        name: "All Answered Question",
        component: AllAnsweredQuestion
      },
      {
        path: "all-answered-question/:questionId/answer",
        name: "Answer",
        component: Answer
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile
      },
      {
        path: "/notification",
        name: "Notification",
        component: Notification
      },
      {
        path: "details/:questionId/:flag",
        name: "NotificationDetail",
        component: NotificationDetail
      },
      {
        path: "/flagquestion",
        name: "FlagQuestion",
        component: FlagQuestion
      },
      {
        path: "/allflagquestion",
        name: "AllFlagQuestion",
        component: AllFlagQuestion
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {

    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
