<template>
  <aside class="main-nav">
    <div class="d-block d-lg-none py-3 px-3 mini-logo-bar">
      <router-link
        class="navbar-brand m-0 p-0"
        to="/question"
      >
        <img
          src="../assets/images/mini-logo.png"
          class="d-block d-lg-none"
          width="50"
          alt="site-logo"
          @click="closeMenu()"
        >
      </router-link>
      <button
        class="navbar-toggler float-right"
        @click="closeMenu()"
      >
        <img
          src="../assets/images/close.png"
          width="24"
          class="open-menu"
          alt=""
        >
      </button>
    </div>
    <div class="sidebar">
      <ul
        class="menu-list list-unstyled"
        id="menu"
      >
        <li>
          <!-- <button
            class="list-item"
            data-toggle="collapse"
            aria-expanded="true"
            aria-controls="collapseOne"
          > -->
          <button
            class="list-item"
            @click="toggle()"
            id="question-menu"
          >
            <img
              src="../assets/images/question.png"
              width="24"
              alt="question"
            >
            Questions
          </button>
          <ul
            class="list-unstyled sunbmenu collapse"
            id="collapseOne"
            aria-labelledby="question"
            data-parent="#menu"
          >
            <li @click="closeMenu()">
              <router-link to="/question">
                new question queue
              </router-link>
            </li>
            <li @click="closeMenu()">
              <router-link to="/claimed-question">
                <span id="title"> my claimed questions </span>
                <span id="claimedCount">{{ this.claimedCount }}</span>
              </router-link>
            </li>
            <li @click="closeMenu()">
              <router-link to="/answered-question">
                my answered questions
              </router-link>
            </li>
            <li @click="closeMenu()">
              <router-link to="/all-answered-question">
                All answered questions
              </router-link>
            </li>
            <li @click="closeMenu()">
              <router-link to="/flagquestion">
                My Flagged questions
              </router-link>
            </li>
            <li @click="closeMenu()">
              <router-link to="/allflagquestion">
                All Flagged questions
              </router-link>
            </li>
          </ul>
        </li>
        <li
          @click="
            closeMenu();
            closeToggle();
          "
          class="nav-btn"
        >
          <router-link
            to="/notification"
            class="list-item collapsed"
          >
            <img
              src="../assets/images/notification.png"
              width="24"
              alt="Notification"
            >
            Notification
          </router-link>
        </li>
        <li
          @click="
            closeMenu();
            closeToggle();
          "
          class="nav-btn"
        >
          <router-link
            to="/profile"
            class="list-item collapsed"
          >
            <img
              src="../assets/images/user-profile.png"
              width="24"
              alt="profile"
            >
            My profile
          </router-link>
        </li>
        <li class="nav-btn">
          <a
            class="list-item collapsed hand-pointer"
            @click="logout()"
          >
            <img
              src="../assets/images/logout.png"
              width="24"
              alt="logout"
            >
            Logout
          </a>
        </li>
        <!-- <li class="nav-btn collapse-menu">
          <a
            class="list-item collapsed hand-pointer"
            @click="openMenu()"
          >
            <i class="fa fa-chevron-circle-left" />
            Collapse Menu
          </a>
        </li> -->
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      menuShow: true,
      claimedCount: localStorage.getItem("claimedCount")
    };
  },
  mounted: function () {
    this.claimedCount= localStorage.getItem("claimedCount");
    if(this.claimedCount > 0){
      document.getElementById("claimedCount").innerHTML = this.claimedCount;
      document.getElementById("claimedCount").style.display = "block";
    }else{
      document.getElementById("claimedCount").style.display = "none";
    }
    let currentUrl = window.location.pathname;
    if (currentUrl.includes("question")) {
      this.toggle();
    }
  },
  methods: {
    closeMenu() {
      document.getElementsByTagName("body")[0].classList.remove("opne-menu");
    },
    toggle() {
      if (this.menuShow) {
        this.menuShow = false;
        document.getElementById("collapseOne").classList.add("show");
        document.getElementById("question-menu").classList.add("active");
        let currentUrl = window.location.pathname;
        if (!currentUrl.includes("question")) {
          this.$router.push("/question");
        }
      } else {
        this.menuShow = true;
        document.getElementById("collapseOne").classList.remove("show");
        document.getElementById("question-menu").classList.remove("active");
      }
    },
    closeToggle() {
      this.menuShow = true;
      document.getElementById("collapseOne").classList.remove("show");
      document.getElementById("question-menu").classList.remove("active");
    },
    logout() {
      localStorage.clear();
      this.$toastMessage.success("Logout successfully");
      this.$router.push("/");
    },
    openMenu() {
      let bodyElement = document.getElementsByTagName("body")[0];
      if (bodyElement.classList.contains("opne-menu")) {
        bodyElement.classList.remove("opne-menu");
        return;
      }
      bodyElement.classList.add("opne-menu");
      this.closeToggle();
    },
  },
};
</script>
<style>
span#claimedCount {
  background: red;
  border-radius: 30px;
  padding: 2px 4px;
  color: white;
  font-size: 10px;
  min-width: 22px;
  height: 18px;
  text-align: center;
  line-height: 12px;
  position: absolute;
  top: calc(50% - 7px);
  right: 20px;
}
aside.main-nav .sidebar .menu-list li .sunbmenu li a {
  position: relative;
}
span#title {
  width: 90%;
  font-size: 15px;
}
</style>