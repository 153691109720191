<template>
  <main class="main-contant">
    <div class="clearfix">
      <h1 class="page-title float-left">
        profile
      </h1>
    </div>
    <div class="page-content">
      <div class="card-wrap p-3 p-lg-5 mb-4">
        <form action="">
          <div class="profile_wrap">
            <div class="row">
              <div class="col-xl-9">
                <div class="form-group">
                  <label for="">profile picture</label>
                  <div class="input-wrap">
                    <div class="profile-wrap">
                      <img
                        alt="profile"
                        ref="avatar"
                        :src="profileInfo.avatar || defaultAvatar"
                      >
                    </div>
                    <div class="profile-wrap">
                      <div class="upload-btn">
                        <i class="fa fa-picture-o" />Upload
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          @change="fileChangeEvent($event)"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Name</label>
                  <div class="input-wrap">
                    <input
                      type="text"
                      placeholder="Name"
                      class="form-control"
                      v-model="profileInfo.name"
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Email</label>
                  <div class="input-wrap">
                    <input
                      type="email"
                      placeholder="Email"
                      class="form-control"
                      v-model="profileInfo.email"
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Password</label>
                  <div class="input-wrap">
                    <button
                      class="btn drbtn btn-outline-primary"
                      type="button"
                      @click="onPasswordChangeHandler()"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="isChangePassword"
                >
                  <label for="">Current Password</label>
                  <div class="input-wrap">
                    <input
                      type="password"
                      placeholder="Current Password"
                      class="form-control"
                      v-model="profileInfo.current_password"
                    >
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="isChangePassword"
                >
                  <label for="">New Password</label>
                  <div class="input-wrap">
                    <input
                      type="password"
                      placeholder="New Password"
                      class="form-control"
                      v-model="profileInfo.new_password"
                    >
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="isChangePassword"
                >
                  <label for="">Confirm Password</label>
                  <div class="input-wrap">
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      class="form-control"
                      v-model="profileInfo.confirm_password"
                    >
                  </div>
                </div>
                <div class="form-group text-right">
                  <button
                    class="btn px-5 mx-2 my-3 drbtn btn-outline-primary"
                    type="button"
                    @click="onSubmit()"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="card-heading">
          <h3>My Expertise</h3>
        </div>
        <!-- v-model="selected" -->
        <VSelect
          @input="setSelected"
          :options="expertiseList"
          :disabled="disabled"
          :searchable="searchable"
          search-placeholder="Search Bar"
          search-not-found="Record Not Found"
          default-title="Select Expertise"
          class="expert-drp"
        />
        <div class="mt-3">
          <ul class="expert-list">
            <li
              v-for="(expertise, index) in selected"
              :key="index"
            >
              <span>
                {{ expertise.text }} <span
                  @click="remove(index,expertise.id)"
                  class="removeBtn"
                > <img src="../../assets/images/xmark-solid.svg"> </span>
              </span>
            </li>
          </ul>
        <!-- <strong>{{ selected }}</strong> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import VSelect from "vue-select-picker-bootstrap";
export default {
  name: "Profile",
  components: {
    VSelect,
  },
  data() {
    return {
      profileInfo: {
        avatar: null,
      },
      copyProfileInfo: {},
      isChangePassword: false,
      defaultAvatar: null,
      expertiseList: [],
      disabled: false,
      searchable: true,
      selected: [],
    };
  },
  methods: {
    remove(index,id) {
      this.removeDoctorExpertise(id);
      this.$delete(this.selected,index);
      return true;
    },
    expertiseExists(name) {
     return this.selected.some(function (el,index) {
        if(el.value === name){
          return true;
        }else{
          return false;
        }
      });
    },
    setSelected(data) {
      console.log(this.profileInfo.id);
     
      // if not exit then remove
      if (!this.expertiseExists(data.value)) {
        // call insert api Here
        this.addDoctorExpertise(data);
      } 
    },
    getProfile() {
      let loader = Vue.$loading.show();

      this.$apiService
        .getProfile()
        .then((response) => {
          loader.hide();

          let res = response.data.data;

          if (response.status == 200) {
            this.profileInfo = res;
            // get all expertise of doctor;
            let doctorId = res.id;
            this.getDoctorExpertise(doctorId);

            this.copyProfileInfo = this.parseJSON(
              JSON.stringify(this.profileInfo)
            );
          }
        })
        .catch((e) => {
          loader.hide();
          this.profileInfo = { avatar: null };
        });
    },
    onSubmit() {
      if (this.profileInfo.name == undefined || this.profileInfo.name == "") {
        this.$toastMessage.error("Name is required");
        return false;
      }

      if (this.profileInfo.email == undefined || this.profileInfo.email == "") {
        this.$toastMessage.error("Email is required");
        return false;
      }

      if (!this.$apiService.validateEmail(this.profileInfo.email)) {
        this.$toastMessage.error("Please enter valid email");
        return false;
      }

      if (this.isChangePassword) {
        if (
          this.profileInfo.current_password == undefined ||
          this.profileInfo.current_password == ""
        ) {
          this.$toastMessage.error("Current password is required");
          return false;
        }

        if (
          this.profileInfo.new_password == undefined ||
          this.profileInfo.new_password == ""
        ) {
          this.$toastMessage.error("New password is required");
          return false;
        }
        if (
          this.profileInfo.confirm_password == undefined ||
          this.profileInfo.confirm_password == ""
        ) {
          this.$toastMessage.error("Confirm password is required");
          return false;
        }

        if (
          this.profileInfo.new_password &&
          this.profileInfo.confirm_password &&
          this.profileInfo.new_password != this.profileInfo.confirm_password
        ) {
          this.$toastMessage.error("Confirm password and password must match");
          return false;
        }
      }

      let loader = Vue.$loading.show();

      let sendData = {
        name: this.profileInfo.name,
        email: this.profileInfo.email,
      };

      if (this.profileInfo.new_password) {
        sendData.new_password = this.profileInfo.new_password;
      }

      if (this.profileInfo.current_password) {
        sendData.current_password = this.profileInfo.current_password;
      }

      this.$apiService
        .updateProfile(sendData)
        .then((response) => {
          loader.hide();
          let res = response.data;

          if (response.status == 200) {
            this.$toastMessage.success(res.message);
            this.$eventBus.$emit(
              "profile-name-change-event",
              this.profileInfo.name
            );
            this.isChangePassword = false;
            this.profileInfo.current_password = "";
            this.profileInfo.new_password = "";
            this.profileInfo.confirm_password = "";
          }
        })
        .catch((e) => {
          loader.hide();
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          } else {
            this.profileInfo = this.parseJSON(
              JSON.stringify(this.copyProfileInfo)
            );
          }
        });
    },
    onPasswordChangeHandler() {
      this.isChangePassword = !this.isChangePassword;
      this.profileInfo.current_password = "";
      this.profileInfo.new_password = "";
      this.profileInfo.confirm_password = "";
    },
    fileChangeEvent(event) {
      let loader = Vue.$loading.show();

      let formData = new FormData();
      formData.append("avatar", event.target.files[0]);

      this.$apiService
        .updateProfileImage(formData)
        .then((response) => {
          loader.hide();
          let res = response.data;

          if (response.status == 200) {
            this.profileInfo.avatar = res.data.avatar;
            this.$eventBus.$emit(
              "profile-avatar-change-event",
              res.data.avatar
            );
            this.$toastMessage.success(res.message);
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    getAllExpertise() {
      let loader = Vue.$loading.show();

      this.$apiService
        .getAllExpertise()
        .then((response) => {
          loader.hide();
          let res = response.data.data;
          this.expertiseList = res.map((val) => {
            return {
              value: val.id,
              text: val.expertise_name,
            };
          });
        })
        .catch((e) => {
          loader.hide();
        });
    },
    getDoctorExpertise(doctorId) {
      let loader = Vue.$loading.show();
      this.$apiService
        .getDoctorExpertise(doctorId)
        .then((response) => {
          loader.hide();
          let res = response.data.data;
          this.selected = res.expertise.map((val) => {
            return {
              value: val.expertise_id,
              text: val.expertise_name,
              id:val.pivot.id
            };
          });
        })
        .catch((e) => {
          loader.hide();
        });
    },
    removeDoctorExpertise(id) {
      let loader = Vue.$loading.show();

      this.$apiService
        .removeDoctorExpertise(id)
        .then((response) => {
          loader.hide();
         if (response.data.success) {
            this.$toastMessage.success(response.data.message);
            this.getAllExpertise();
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    addDoctorExpertise(data) {
      let loader = Vue.$loading.show();
      let sendData = {
        expertise_id: data.value,
        doctor_id: this.profileInfo.id
      };
      console.log(data);
      this.$apiService
        .addDoctorExpertise(sendData)
        .then((response) => {
          let res = response.data;
          let insertedId = res.data.id;
          if(insertedId > 0) {
            this.selected.push({
              value: data.value,
              text: data.text,
              id : insertedId
            });
          }
          loader.hide();
          if (response.data.success) {
            this.$toastMessage.success(res.message);
            this.getAllExpertise();
          }
        })
        .catch((e) => {
          loader.hide();
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          } 
        });
    },
  },
  created() {
    this.getProfile();
  },
  mounted() {
    this.getAllExpertise();
    this.defaultAvatar = this.$apiService.getDefaltAvatar();
    this.$nextTick(function () {
      if (this.$refs.avatar) {
        this.$refs.avatar.addEventListener("error", function () {
          this.$refs.avatar.src = this.defaultAvatar;
        });
      }
    });
  },
};
</script>