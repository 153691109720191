import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueAnalytics from "vue-analytics";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import APIService from "./services/apiService";
import ToasterService from "./services/toasterService";
import { EventBus } from "./services/event-bus";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URI;

// Interceptor
axios.interceptors.request.use(
	function(config) {
		// Do something before request is sent
		return config;
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	function(response) {
		// Do something with response data
		return response;
	},
	function(error) {
		// Do something with response error
		if (error.response.status == 401) {
			localStorage.clear();
			window.location.href = "/";
		}

		return Promise.reject(error);
	}
);

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.use(Loading, {
	color: "#ec5134",
});

//and then use it in main.js
Vue.use(VueAnalytics, {
	id: "G-T6EWRFLT2G",
	router,
});

// Add global properties
Vue.prototype.$apiService = new APIService();
Vue.prototype.$toastMessage = new ToasterService();
Vue.prototype.$eventBus = EventBus;

Vue.mixin({
	methods: {
		parseJSON(value) {
			let result = {};
			try {
				result = JSON.parse(value);
			} catch (error) {
				result = {};
			}
			return result;
		},
	},
});

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
