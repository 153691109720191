<script>
export default {
  data() {
    return {
      page: 1,
      page_size: 10,
      totalCount: 0,
      firstText: 0,
      secondText: 0,
      filter: {},
      filteredCategories: [],
      questionList: [],
      categoriesList: [],
      perPageOptions: [10, 25, 50, 100],
      dateOptions: [
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This month",
        "This year",
        "Last year",
        "Custom",
        "Older to Newest",
        "Newest to Oldest",
      ],
    };
  },
  watch: {
    filteredCategories(value) {
      this.filter.categories = value;
    },
  },
  computed: {
    normalizedCategories() {
      return this.categoriesList.length > 0
        ? this.categoriesList.map((c) => this.normalizeCategory(c))
        : [];
    },
  },
  methods: {
    resetData() {
      this.page = 1;
      this.page_size = 10;
      this.totalCount = 0;
      this.firstText = 0;
      this.secondText = 0;
      this.questionList = [];
      this.filter = {
        date: "",
        search: "",
        categories: 0,
      };
      this.categoriesList = [];
    },

    normalizeCategory(category) {
      const result = {
        id: category.id,
        label: category.title,
      };
      if (category.children && category.children instanceof Array) {
        result.children = category.children.map((c) =>
          this.normalizeCategory(c)
        );
      }
      return result;
    },
    getCategory() {
      let params = {
        locale: this.parseJSON(localStorage.getItem("selectedCountry"))[
          "locale"
        ],
      };

      this.$apiService
        .getCategory(params)
        .then((response) => {
          let res = response.data.data;

          if (response.status == 200) {
            this.categoriesList = res;
          }
        })
        .catch((e) => {
          this.categoriesList = [];
        });
    },
    dateChange() {
      if (this.filter.start_date) {
        delete this.filter.start_date;
      }

      if (this.filter.end_date) {
        delete this.filter.end_date;
      }
    },
  },
};
</script>
