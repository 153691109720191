<template>
  <main class="main-contant">
    <div class="clearfix">
      <h1 class="page-title float-left">
        question
      </h1>
    </div>
    <div class="page-content d-flex flex-wrap">
      <h2 class="question-head">
        {{ questionInfo.question }}
      </h2>
      <span
        style="float: right; color: #ec5134; padding: 0rem 0.75rem"
        v-if="questionInfo.docFlag || questionInfo.adminFlag"
        v-b-modal="'flag-comment-modal'"
        @click="selectedQuestion = questionInfo"
      ><i
        class="fa fa-flag"
        aria-hidden="true"
      />
      </span>
      <div
        class="question-head-flag ml-3"
      >
        <b-dropdown
          class="flagDropdown "
          variant="btn dropdown-toggle btn-secondary"
          toggle-class="text-decoration-none"
          no-caret
          :text="questionInfo.flag ? questionInfo.flag : 'Flag'"
        >
          <b-dropdown-item
            @click="
              selectForFlag = 1;
              selectForClaim = questionInfo.id;
            "
            v-b-modal="'flag-modal'"
            :class="
              questionInfo.flag_status === 1 ? 'dropdown-active' : ''
            "
          >
            Legal Implication
          </b-dropdown-item>
          <b-dropdown-item
            @click="
              selectForFlag = 2;
              selectForClaim = questionInfo.id;
            "
            v-b-modal="'flag-modal'"
            :class="
              questionInfo.flag_status === 2 ? 'dropdown-active' : ''
            "
          >
            Can not answer
          </b-dropdown-item>
          <b-dropdown-item
            @click="
              selectForFlag = 4;
              selectForClaim = questionInfo.id;
            "
            v-b-modal="'flag-modal'"
            :class="
              questionInfo.flag_status === 4 ? 'dropdown-active' : ''
            "
          >
            Insufficient Information
          </b-dropdown-item>
          <b-dropdown-item
            @click="
              selectForFlag = 3;
              selectForClaim = questionInfo.id;
            "
            v-b-modal="'flag-modal'"
            :class="
              questionInfo.flag_status === 3 ? 'dropdown-active' : ''
            "
          >
            Other
          </b-dropdown-item>
        </b-dropdown>
      </div>
      
      <div class="row">
        <div class="col-md-4">
          <div class="mt-4">
            <div class="question-form">
              <h5>Question Info</h5>
              <div
                class="list-group"
                style="border: none"
              >
                <a
                  v-for="(normalizedInfo, index) in normalizedQuestionInfo"
                  :key="index"
                  href="javascript:;"
                  class="list-group-item list-group-item-action flex-column align-items-start"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h5
                      style="font-size: 1rem"
                      class="mb-1"
                    >
                      {{ normalizedInfo.title }}
                    </h5>
                  </div>
                  <p>
                    {{ normalizedInfo.normalizedValue || normalizedInfo.value }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <button
            class="drbtn btn btn-outline-primary ml-2"
            v-b-modal="'claim-modal'"
            @click="selectForClaim = questionInfo.id"
            v-if="questionInfo.claimBtnFlag && Boolean(!questionInfo.claimed_by)"
            style="float: right"
          >
            Claim
          </button> 
          <button
            class="drbtn btn btn-outline-primary ml-2"
            v-b-modal="'unclaim-modal'"
            @click="selectForClaim = questionInfo.id"
            v-if="questionInfo.claimBtnFlag && Boolean(questionInfo.claimed_by)"
            style="float: right"
          >
            Unclaim
          </button>
          <h5 class="mt-4 ">
            Response
          </h5> 
          
          <div class="card-wrap mt-1">
            <treeselect
              v-model="filteredCategories"
              clearable
              searchable
              search-nested
              placeholder="Add categories"
              class="form-control"
              style="padding: 0px"
              :multiple="true"
              :options="normalizedCategories"
              :limit="3"
              :disabled="questionInfo.is_published || !questionInfo.claimed_by"
            />
            <p class="m-0">
              (<i>Add as many categories as will apply</i>)
            </p>
          </div>

          <div class="card-wrap mt-4">
            <div class="form-group">
              <div class="row mb-2">
                <div class="col-md-5">
                  <label>Answer</label>
                </div>
                <div
                  class="col-md-7 text-right"
                  v-if="!questionInfo.is_published && questionInfo.claimed_by"
                >
                  <b-form-select
                    v-model="defAnswer"
                    placeholder="Select Boilerplate answer..."
                    @change="defAnswerSelected"
                  >
                    <b-form-select-option :value="null">
                      (Optional) Select Boilerplate answer...
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="boilerplate in questionInfo.boilerplate_answers"
                      :value="boilerplate.id"
                      :key="boilerplate.title"
                    >
                      {{ boilerplate.title }}
                    </b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              <textarea
                type="text"
                placeholder="Answer"
                class="form-control"
                rows="5"
                v-html="questionInfo.content"
                v-model="questionInfo.content"
                :disabled="
                  questionInfo.is_published || !questionInfo.claimed_by
                "
              />
            </div>
            <div
              class="form-group mt-5"
              v-if="!questionInfo.is_published && questionInfo.claimed_by"
            >
              <div class="row">
                <div class="col-md-6">
                  <label
                    class="hand-pointer"
                    style="color:#53bbb4"
                    @click="redirectToGuidelines()"
                  >Ask Dr FIGO Guidelines</label>
                </div>
                <div class="col-md-6 text-right">
                  <button
                    class="drbtn btn btn-outline-primary mr-2"
                    @click="onSubmit(0)"
                  >
                    Save as draft
                  </button>
                  <button
                    class="drbtn btn btn-outline-primary"
                    @click="onSubmit(1)"
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="claim-modal"
      centered
      title="Confirm"
      @ok="claimQuestion"
    >
      Are you sure you want to claim this question?
    </b-modal>
    <b-modal
      id="unclaim-modal"
      centered
      title="Confirm"
      @ok="unclaimQuestion"
    >
      Are you sure you want to unclaim this question?
    </b-modal>
    <b-modal
      id="flag-modal"
      centered
      title="Confirm"
      @ok="selectFlagHandler"
    >
      Are you sure you want to flag this question?
    </b-modal>
    <b-modal
      v-model="commentModalShow"
      centered
      title="Comment"
      @ok="commentHandler"
    >
      <textarea
        type="text"
        placeholder="Comment"
        class="form-control"
        rows="5"
        v-model="flagComment"
      />
    </b-modal>
    <b-modal
      id="flag-comment-modal"
      centered
      ok-only
    >
      <div v-if="questionInfo.docFlag">
        <h5>
          Doctor flagged as
          {{ docFlagInfo[questionInfo.flag_status - 1] }}
        </h5>
        <h5 v-if="questionInfo.flag_status == 3">
          comment:
        </h5>
        <p v-if="questionInfo.flag_status == 3">
          {{ questionInfo.comment }}
        </p>
      </div>
      <div v-if="questionInfo.adminFlag">
        <hr>
        <h5>
          Admin
          {{
            questionInfo.accepted_status == 0 ? "Rejected" : "Accepted"
          }}
          the flag
        </h5>
        <h5 v-if="questionInfo.admin_comment != null">
          Comment:
        </h5>
        <div
          v-html="questionInfo.admin_comment"
          v-if="questionInfo.admin_comment != null"
        />
      </div>
    </b-modal>
  </main>
</template>

<script>
import Vue from "vue";
import Treeselect from "@riophae/vue-treeselect";

export default {
  components: { Treeselect },
  data() {
    return {
      categoriesList: [],
      filteredCategories: [],
      value: [],
      questionId: "",
      questionInfo: {},
      defAnswer: null,
      defaultAnswer1:
        "Thank you for your question. Unfortunately, you did not supply enough information for our doctors to properly respond. Please ask again, and make sure to include more details (but make sure you do not identify yourself).",
      defaultAnswer2:
        "Thank you for your question. However, we cannot answer your question at this time. Please try submitting your question again, and keep in mind the following: 1. AskDrFIGO.org is bound by the local laws in your country 2. AskDrFIGO.org is a place to ask questions about Sexual and Reproductive Health and Rights (SRHR)",
        docFlagInfo: [
          "Legal Implication",
          "Can not answer",
          "Other",
          "Insufficient Information",
        ],
        selectForFlag: null,
        commentModalShow: false,
        flagComment: "",
        selectedQuestion: {},
        claimedCount: localStorage.getItem("claimedCount"),
    };
  },
  computed: {
    normalizedQuestionInfo() {
      return [
        {
          title: "Gender",
          value: this.questionInfo.gender_value,
        },
        {
          title: "Age",
          value: this.questionInfo.age_value,
        },
        {
          title: "Are You Pregnant Right Now",
          value: this.questionInfo.is_pregnant,
          normalizedValue: this.booleanNormalizer(
            this.questionInfo.is_pregnant
          ),
        },
        {
          title: "Are You Sexually Active Right Now",
          value: this.questionInfo.is_sexually_active,
          normalizedValue: this.booleanNormalizer(
            this.questionInfo.is_sexually_active
          ),
        },
        {
          title: "How Much Education Have You Had",
          value: this.questionInfo.education_value,
        },
        {
          title: "Have You Ever Seen A Women’s Health Doctor",
          value: this.questionInfo.ever_seen_doctor,
          normalizedValue: this.booleanNormalizer(
            this.questionInfo.ever_seen_doctor
          ),
        },
        {
          title: "Are You In A Relationship",
          value: this.questionInfo.in_relationship,
          normalizedValue: this.booleanNormalizer(
            this.questionInfo.in_relationship
          ),
        },
      ];
    },
    normalizedCategories() {
      return this.categoriesList.length > 0
        ? this.categoriesList.map((c) => this.normalizeCategory(c))
        : [];
    },
  },
  methods: {
    booleanNormalizer(value) {
      return value == null ? "N/A" : value ? "Yes" : "No";
    },
    getQuestionById() {
      let loader = Vue.$loading.show();

      let params = {
        locale: this.parseJSON(localStorage.getItem("selectedCountry"))[
          "locale"
        ],
      };
      this.$apiService
        .getQuestionById(this.questionId, params)
        .then((response) => {
          loader.hide();

          let res = response.data;
          if (res.success) {
            this.questionInfo = res.data;
              if (this.questionInfo.flag_status == null && this.questionInfo.accepted_status == null) {
                this.questionInfo.docFlag = false;
                this.questionInfo.adminFlag = false;
                this.questionInfo.claimBtnFlag = true;
              }

              if (this.questionInfo.flag_status != null && this.questionInfo.accepted_status == null) {
                this.questionInfo.docFlag = true;
                this.questionInfo.adminFlag = false;
                this.questionInfo.claimBtnFlag = false;
              }

              if (this.questionInfo.flag_status != null && this.questionInfo.accepted_status != null) {
                this.questionInfo.docFlag = true;
                this.questionInfo.adminFlag = true;
                if (this.questionInfo.flag_status == 2 && this.questionInfo.accepted_status == 1) {
                  this.questionInfo.claimBtnFlag = false;
                } else {
                  this.questionInfo.claimBtnFlag = true;
                }
              }
            if (this.questionInfo.categories.length > 0) {
              this.filteredCategories =
                this.questionInfo.categories.split(", ");
              this.filteredCategories = this.filteredCategories.map(function (
                x
              ) {
                return parseInt(x, 10);
              });
            } else {
              this.filteredCategories = [];
            }
          }
        })
        .catch((e) => {
          loader.hide();
          this.errors.push(e);
        });
    },
    onSubmit(isPublishFlag) {
      if (
        this.questionInfo.content == undefined ||
        this.questionInfo.content == ""
      ) {
        this.$toastMessage.error("Answer is required");
        return false;
      }

      if (isPublishFlag == 1) {
        if (this.value.length == 0) {
          this.$toastMessage.error("Categories is required");
          return false;
        }
      }

      let loader = Vue.$loading.show();

      let sendData = {
        content: this.questionInfo.content,
        is_published: isPublishFlag,
        category_id: this.value.join(", "),
      };

      this.$apiService
        .submitAnswer(sendData, this.questionId)
        .then((response) => {
          loader.hide();
          let res = response.data;

          if (response.status == 200) {
            this.$toastMessage.success(res.message);
            this.getQuestionById();
          }
        })
        .catch((e) => {
          loader.hide();
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
    getCategory() {
      let params = {
        locale: this.parseJSON(localStorage.getItem("selectedCountry"))[
          "locale"
        ],
      };

      this.$apiService
        .getCategory(params)
        .then((response) => {
          let res = response.data.data;
          if (response.status == 200) {
            this.categoriesList = res;
          }
        })
        .catch((e) => {
          this.categoriesList = [];
        });
    },
    redirectToGuidelines() {
      this.$router.push("/guidelines?redirect=" + window.location.pathname);
    },
    normalizeCategory(category) {
      const result = {
        id: category.id,
        label: category.title,
      };
      if (category.children && category.children instanceof Array) {
        result.children = category.children.map((c) =>
          this.normalizeCategory(c)
        );
      }
      return result;
    },
    defAnswerSelected() {
      const answer = (this.questionInfo.boilerplate_answers || []).find(
        (a) => (a.id = this.defAnswer)
      );
      if (answer) {
        this.questionInfo.content =
          this.questionInfo.content != null && this.questionInfo.content != ""
            ? this.questionInfo.content + " " + answer.content
            : answer.content;
      }
    },
    claimQuestion() {
      this.$apiService
        .claimQuestion({}, this.selectForClaim)
        .then((response) => {
          let res = response.data;

          if (response.status == 200) {
            this.$toastMessage.success(res.message);
            this.questionInfo.claimed_by=true;
            localStorage.setItem("claimedCount",parseInt(this.claimedCount) + parseInt(1));
            this.claimedCount = parseInt(this.claimedCount) + parseInt(1);
            if(this.claimedCount > 0){
              document.getElementById("claimedCount").innerHTML=this.claimedCount;
              document.getElementById("claimedCount").style.display = "block";
            }else{
              document.getElementById("claimedCount").style.display = "none";
            }
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
    unclaimQuestion() {
      this.$apiService
        .unclaimQuestion({}, this.selectForClaim)
        .then((response) => {
          let res = response.data;

          if (response.status == 200) {
            this.$toastMessage.success(res.message);
            this.questionInfo.claimed_by=false;
            localStorage.setItem("claimedCount",parseInt(this.claimedCount) - parseInt(1));
            this.claimedCount = parseInt(this.claimedCount) - parseInt(1);
            if(this.claimedCount > 0){
              document.getElementById("claimedCount").innerHTML=this.claimedCount;
              document.getElementById("claimedCount").style.display = "block";
            }else{
              document.getElementById("claimedCount").style.display = "none";
            }

          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
    selectFlagHandler() {
      if (this.selectForFlag == 3) {
        this.commentModalShow = true;
      } else {
        this.flagQuestion();
      }
    },
    commentHandler(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.flagComment == undefined || this.flagComment == "") {
        this.$toastMessage.error("Comment is required");
        return false;
      }
      this.commentModalShow = false;
      this.flagQuestion();
    },
    flagQuestion() {
      let loader = Vue.$loading.show();
      let sendData = {
        flag_status: this.selectForFlag,
        comment: this.flagComment,
      };

      this.$apiService
        .flagQuestion(sendData, this.selectForClaim)
        .then((response) => {
          let res = response.data;
          if (response.status == 200) {
            loader.hide();
            this.$toastMessage.success(res.message);
            this.getQuestionById();
            this.getCategory();
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            loader.hide();
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
  },
  mounted() {
    this.questionId = this.$route.params.questionId;
    this.getQuestionById();
    this.getCategory();
  },
  watch: {
    filteredCategories(value) {
      this.value = value;
    },
  },
};
</script>

<style scoped>
.use-answer-btn {
  background: #53bbb4;
  color: #fff;
  padding: 2px 8px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 95%;
  text-transform: none;
}
.flagDropdown {
  /*border: 1px solid #53bbb4;*/
  border-radius: 0.25rem;
  align-items: center;
}
.flagDropdown.dropdown.b-dropdown button {
  background: transparent !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  padding-left: 2px !important;
}
</style>
