<template>
  <main class="main-contant">
    <div class="clearfix">
      <h1 class="page-title float-left">
        Notifications <img
          src="../../assets/images/notification.png"
          width="24"
          alt="Notification"
        >
      </h1>
    </div>
    <div class="page-content">
      <div class="card-wrap">
        <div
          class="table-responsive"
          v-if="notificationList.length > 0"
        >
          <table class="table">
            <thead>
              <tr>
                <th>
                  <span
                    class="hand-pointer"
                    @click="orderByHandler('asc', 'question')"
                  >Notification Title
                    <i
                      class="fa fa-caret-up"
                      :class="
                        filter.sort_on == 'questions.question' &&
                          filter.sort_type == 'asc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    />
                    <i
                      class="fa fa-caret-down"
                      :class="
                        filter.sort_on == 'questions.question' &&
                          filter.sort_type == 'desc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    /></span>
                </th>
                <th>
                  <span
                    class="hand-pointer"
                  >
                    Created On
                  </span>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(question, index) of notificationList"
                :key="index"
                :class="{'read': question.flag == true, 'unread': question.flag == false }"
              >
                <td>
                  <a
                    href="javascript:;"
                    @click="notificationScreen(question.type,question.question_id,question.id,question.flag)"
                    class="ellipsis"
                  >
                    <img
                      v-if="question.type == 1"
                      src="../../assets/images/suggestion.png"
                      width="25"
                      alt="Suggested Question"
                      title="Suggested Question"
                    >
                    <img
                      v-if="question.type == 2"
                      src="../../assets/images/reminder.png"
                      width="25"
                      alt="8 Hours Remind"
                      title="8 Hours Remind"
                    >
                    <img
                      v-if="question.type == 3"
                      src="../../assets/images/reminder.png"
                      width="25"
                      alt="20 Hours Remind"
                      title="20 Hours Remind"
                    >
                    <img
                      v-if="question.type == 4"
                      src="../../assets/images/draft.png"
                      width="25"
                      alt="Draft"
                      title="Draft"
                    >
                    {{ question.question }}</a>
                </td>
                <td>{{ question.created_at | formatDate }}</td>
                <td>
                  <span
                    style="float: right; padding: 0rem 0.75rem;margin-right: 12px;font-style: italic;"
                  >
                    {{ question.times }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="text-center"
          v-if="notificationList.length == 0"
        >
          <p>No Notification Available</p>
        </div> 
        <div
          class="paginations"
          v-if="totalCount > page_size"
        >
          <p>Rows per page:</p>
          <select
            name="limit"
            v-model="page_size"
            @change="onLimitChange()"
          >
            <option
              v-for="pageSize in perPageOptions"
              :key="pageSize"
              :value="pageSize"
            >
              {{ pageSize }}
            </option>
          </select>
          <p>
            <span>{{ firstText }}</span>-<span>{{ secondText }}</span> of <span>{{ totalCount }}</span>
            <i
              class="fa fa-angle-left"
              @click="previousPage()"
            />
            <i
              class="fa fa-angle-right"
              @click="nextPage()"
            />
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { exit } from "process";
import FiltersQuestions from "../../mixins/FiltersQuestions.vue";
Vue.filter("formatDate", function (value) {
  if (value) {
    let DateToUtc = moment.utc(value).toDate();
    let DateToLocal = moment(DateToUtc).local().format("MM/DD/YYYY HH:mm:ss");
    return DateToLocal;
  }
});
export default {
  name: "Notification",
  mixins: [FiltersQuestions],
  data() {
    return {
      claimedCount: localStorage.getItem("claimedCount"),
      notificationList: []
    };
  },
  methods: {
    getNotification() {
      let params = {
        page: this.page,
        page_size: this.page_size,
        country: this.parseJSON(localStorage.getItem("selectedCountry"))["id"],
        status_type: 0,
        locale: this.parseJSON(localStorage.getItem("selectedCountry"))[
          "locale"
        ],
        userId: localStorage.getItem("userId"),
      };
     
      if (this.filter.hasOwnProperty("sort_on")) {
        params.sort_on = this.filter.sort_on;
      }

      if (this.filter.hasOwnProperty("sort_type")) {
        params.sort_type = this.filter.sort_type;
      }
      
      // pass userId for check Question
      if(localStorage.getItem("userId"))
      {
        params.userId = localStorage.getItem("userId");
      }

      let loader = Vue.$loading.show();

      this.$apiService
        .getNotification(params)
        .then((response) => {
          loader.hide();

          let res = response.data.data;

          if (response.status == 200) {
            this.notificationList = res.data;
            this.totalCount = res.total;

            if (this.totalCount == 0) {
              this.firstText = 0;
              this.secondText = 0;
            } else {
              this.firstText = this.page * this.page_size - this.page_size + 1;
              if (this.notificationList.length != this.page_size) {
                this.secondText = Math.abs(
                  this.page * this.page_size -
                    this.page_size +
                    this.notificationList.length
                );
              } else {
                this.secondText = this.page * this.page_size;
              }
            }
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    notificationScreen(flag,questionId,id,unreadflag) {
      if(unreadflag == false){
          this.updateFlag(id);
        }
      this.$router.push("/question/" + questionId +"/answer");
      //this.$router.push("/details/" + questionId +"/"+flag);
    },
    updateFlag(notificationId) {
      this.$apiService
        .notificationFlag({}, notificationId)
        .then((response) => {
          let res = response.data;
          if (response.status == 200) {
            this.$toastMessage.success(res.message);
            this.getNotification();
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
    onLimitChange() {
      this.page = 1;
      this.getNotification();
    },
    previousPage() {
      if (this.page > 1) {
        this.page = this.page - 1;
        this.getNotification();
      }
    },
    nextPage() {
      if (this.page < Math.ceil(this.totalCount / this.page_size)) {
        this.page = this.page + 1;
        this.getNotification();
      }
    },
    orderByHandler(order, column) {
      if (this.filter.sort_on == column) {
        this.filter.sort_type = this.filter.sort_type == "asc" ? "desc" : "asc";
      } else {
        this.filter.sort_on = column;
        this.filter.sort_type = order;
      }
      this.getNotification();
    },
  },
  created() {
    this.resetData();
    this.getNotification();
    
  },
  mounted() {
    this.$eventBus.$on("country-change-event", () => {
      this.resetData();
      this.getNotification();
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("country-change-event");
  },
};
</script>

<style scoped>
.guidelines-btn {
  background: #53bbb4;
  color: #fff;
  padding: 2px 8px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 65%;
}.table tr.read a {
  color: #495057;
}

.table tr.unread img,
.table tr.read img {
  display: inline-block;
  width: 40px;
  height: 40px;
  object-fit: contain;
  background: #dcf1f0;
  padding: 8px;
  border-radius: 10px;
  margin-right: 10px;
}

.table tr.read img {
  background: #e9eaec;
}

.table tr.unread {
  background: #f4fafa;
  border-bottom: 1px solid #fff;
  border-left: 2px solid #53bbb4;
}

.table tr.unread a {
  color: #53bbb4;
}
</style>
