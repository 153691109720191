<template>
  <div>
    <Header />
    <Navigation />
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header'
import Navigation from '../components/Navigation'

export default {
  name: 'Layout',
  components: {
    Header,
    Navigation
  }
}
</script>
