<template>
  <main class="main-contant">
    <div class="clearfix">
      <h1 class="page-title float-left">
        New Flagged Question Queue
        <small
          class="guidelines-btn"
          @click="checklistHandler()"
        >Guidelines</small>
      </h1>
    </div>

    <div class="clearfix">
      <h5 class="mt-4 ">
        New Flagged Questions
      </h5>
    </div>
    <div class="page-content">
      <div class="filter-wrap mt-4">
        <ul class="list-inline clearfix">
          <li class="list-inline-item">
            <select
              class="form-control"
              v-model="filter.date"
              @change="dateChange()"
            >
              <option :value="''">
                All Dates
              </option>
              <option
                v-for="(dateOption, index) of dateOptions"
                :key="index"
                :value="index + 1"
              >
                {{ dateOption }}
              </option>
            </select>
          </li>
          <li
            class="list-inline-item"
            v-if="filter.date == '8'"
          >
            <div>
              <b-form-datepicker
                id="example-datepicker"
                placeholder="Start"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                v-model="filter.start_date"
              />
            </div>
          </li>
          <li
            class="list-inline-item"
            v-if="filter.date == '8'"
          >
            <div>
              <b-form-datepicker
                id="example-datepicker1"
                placeholder="End"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                v-model="filter.end_date"
                :min="filter.start_date"
              />
            </div>
          </li>
          <li class="list-inline-item">
            <button
              class="drbtn btn btn-outline-primary filterbtn"
              @click="onSearch()"
            >
              Filter
            </button>
          </li>
          <li class="list-inline-item float-right">
            <ul class="llist-inline pl-0 clearfix m-0 secrch-wrap">
              <li class="list-inline-item float-right mr-0 secrch-btn">
                <button
                  class="drbtn btn btn-outline-primary"
                  @click="onSearch()"
                >
                  Search
                </button>
              </li>
              <li class="list-inline-item float-right mr-3 secrch-input">
                <input
                  type="text"
                  class="form-control"
                  v-model="filter.search"
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="card-wrap">
        <div
          class="table-responsive"
          v-if="questionList.length > 0"
        >
          <table class="table">
            <thead>
              <tr>
                <th>
                  <span
                    class="hand-pointer"
                    @click="orderByHandler('asc', 'question')"
                  >question
                    <i
                      class="fa fa-caret-up"
                      :class="
                        filter.sort_on == 'question' &&
                          filter.sort_type == 'asc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    />
                    <i
                      class="fa fa-caret-down"
                      :class="
                        filter.sort_on == 'question' &&
                          filter.sort_type == 'desc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    /></span>
                </th>
                <th>
                  <span
                    class="hand-pointer"
                    @click="orderByHandler('asc', 'created_at')"
                  >
                    Asked On
                    <i
                      class="fa fa-caret-up"
                      :class="
                        filter.sort_on == 'created_at' &&
                          filter.sort_type == 'asc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    />
                    <i
                      class="fa fa-caret-down"
                      :class="
                        filter.sort_on == 'created_at' &&
                          filter.sort_type == 'desc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    />
                  </span>
                </th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(question, index) of questionList"
                :key="index"
              >
                <td>
                  <a
                    href="javascript:;"
                    @click="answerScreen(question.id)"
                    class="ellipsis"
                  >{{ question.question }}</a>
                </td>
                <td>{{ question.created_at | formatDate }}</td>
                <td>
                  <button
                    class="drbtn btn btn-outline-primary ml-2"
                    v-b-modal="'claim-modal'"
                    @click="selectForClaim = question.id"
                    v-if="question.claimBtnFlag"
                    style="float: right"
                  >
                    Claim
                  </button>
                </td>
                <td>
                  <b-dropdown
                    class="flag-dropdown"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    v-if="!question.docFlag"
                    style="float: right"
                  >
                    <template #button-content>
                      <i
                        class="fa fa-flag"
                        aria-hidden="true"
                        style="color: #000;margin-right:5px"
                      /><i
                        class="fa fa-ellipsis-v"
                        aria-hidden="true"
                      />
                    </template>
                    <b-dropdown-item
                      @click="
                        selectForFlag = 1;
                        selectForClaim = question.id;
                      "
                      v-b-modal="'flag-modal'"
                    >
                      Legal Implication
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        selectForFlag = 2;
                        selectForClaim = question.id;
                      "
                      v-b-modal="'flag-modal'"
                    >
                      Can not answer
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        selectForFlag = 4;
                        selectForClaim = question.id;
                      "
                      v-b-modal="'flag-modal'"
                    >
                      Insufficient Information
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        selectForFlag = 3;
                        selectForClaim = question.id;
                      "
                      v-b-modal="'flag-modal'"
                    >
                      Other
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-- <b>{{ question.flag}}</b> -->
                  <span
                    style="float: right; color: #ec5134; padding: 0rem 0.75rem;margin-right: 12px;"
                    v-if="question.docFlag || question.adminFlag"
                    v-b-modal="'flag-comment-modal'"
                    @click="selectedQuestion = question"
                  ><i
                    class="fa fa-flag"
                    aria-hidden="true"
                    :title="question.flag"
                  />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="text-center"
          v-if="questionList.length == 0"
        >
          <p>No Questions Available</p>
        </div>
        <div
          class="paginations"
          v-if="totalCount > page_size"
        >
          <p>Rows per page:</p>
          <select
            name="limit"
            v-model="page_size"
            @change="onLimitChange()"
          >
            <option
              v-for="pageSize in perPageOptions"
              :key="pageSize"
              :value="pageSize"
            >
              {{ pageSize }}
            </option>
          </select>
          <p>
            <span>{{ firstText }}</span>-<span>{{ secondText }}</span> of <span>{{ totalCount }}</span>
            <i
              class="fa fa-angle-left"
              @click="previousPage()"
            />
            <i
              class="fa fa-angle-right"
              @click="nextPage()"
            />
          </p>
        </div>
        <b-modal
          id="claim-modal"
          centered
          title="Confirm"
          @ok="claimQuestion"
        >
          Are you sure you want to claim this question?
        </b-modal>
        <b-modal
          id="flag-modal"
          centered
          title="Confirm"
          @ok="selectFlagHandler"
        >
          Are you sure you want to flag this question?
        </b-modal>
        <b-modal
          v-model="commentModalShow"
          centered
          title="Comment"
          @ok="commentHandler"
        >
          <textarea
            type="text"
            placeholder="Comment"
            class="form-control"
            rows="5"
            v-model="flagComment"
          />
        </b-modal>
        <b-modal
          id="flag-comment-modal"
          centered
          ok-only
        >
          <div v-if="selectedQuestion.docFlag">
            <h5>
              Doctor flagged as
              {{ docFlagInfo[selectedQuestion.flag_status - 1] }}
            </h5>
            <h5 v-if="selectedQuestion.flag_status == 3">
              comment:
            </h5>
            <p v-if="selectedQuestion.flag_status == 3">
              {{ selectedQuestion.comment }}
            </p>
          </div>
          <div v-if="selectedQuestion.adminFlag">
            <hr>
            <h5>
              Admin
              {{
                selectedQuestion.accepted_status == 0 ? "Rejected" : "Accepted"
              }}
              the flag
            </h5>
            <h5 v-if="selectedQuestion.admin_comment != null">
              Comment:
            </h5>
            <div
              v-html="selectedQuestion.admin_comment"
              v-if="selectedQuestion.admin_comment != null"
            />
          </div>
        </b-modal>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import FiltersQuestions from "../../mixins/FiltersQuestions.vue";
Vue.filter("formatDate", function (value) {
  if (value) {
    let DateToUtc = moment.utc(value).toDate();
    let DateToLocal = moment(DateToUtc).local().format("MM/DD/YYYY HH:mm:ss");
    return DateToLocal;
  }
});

export default {
  name: "FlagQuestion",
  mixins: [FiltersQuestions],
  data() {
    return {
      claimedCount: localStorage.getItem("claimedCount"),
      selectForClaim: "",
      selectForFlag: null,
      commentModalShow: false,
      flagComment: "",
      selectedQuestion: {},
      docFlagInfo: [
        "Legal Implication",
        "Can not answer",
        "Other",
        "Insufficient Information",
      ],
    };
  },
  methods: {
    getFlagQuestionLists() {
      let params = {
        page: this.page,
        page_size: this.page_size,
        country: this.parseJSON(localStorage.getItem("selectedCountry"))["id"],
        status_type: 2,
        locale: this.parseJSON(localStorage.getItem("selectedCountry"))[
          "locale"
        ],
        userId: localStorage.getItem("userId"),
      };

      if (this.filter.hasOwnProperty("date")) {
        params.date = this.filter.date;
      }

      // if (this.filter.date.length > 0) {
      //   params.date = this.filter.date;
      // }

      if (this.filter.categories != 0) {
        params.categories = this.filter.categories;
      }

      if (this.filter.search.length > 0) {
        params.search = this.filter.search;
      }

      if (this.filter.date == "8") {
        params.start_date = this.filter.start_date;
        params.end_date = this.filter.end_date;
      }

      if (this.filter.hasOwnProperty("sort_on")) {
        params.sort_on = this.filter.sort_on;
      }

      if (this.filter.hasOwnProperty("sort_type")) {
        params.sort_type = this.filter.sort_type;
      }
      
      // pass userId for check Question
      if(localStorage.getItem("userId"))
      {
        params.userId = localStorage.getItem("userId");
      }

      let loader = Vue.$loading.show();

      this.$apiService
        .getFlagQuestionLists(params)
        .then((response) => {
          loader.hide();

          let res = response.data.data;

          if (response.status == 200) {
            this.questionList = res.data;
            this.totalCount = res.total;

            this.questionList = this.questionList.map((val) => {
              let flagStatus = {};
              if (val.flag_status == null && val.accepted_status == null) {
                flagStatus.docFlag = false;
                flagStatus.adminFlag = false;
                flagStatus.claimBtnFlag = true;
              }

              if (val.flag_status != null && val.accepted_status == null) {
                flagStatus.docFlag = true;
                flagStatus.adminFlag = false;
                flagStatus.claimBtnFlag = false;
              }

              if (val.flag_status != null && val.accepted_status != null) {
                flagStatus.docFlag = true;
                flagStatus.adminFlag = true;
                if (val.flag_status == 2 && val.accepted_status == 1) {
                  flagStatus.claimBtnFlag = false;
                } else {
                  flagStatus.claimBtnFlag = true;
                }
              }

              return { ...flagStatus, ...val };
            });

            if (this.totalCount == 0) {
              this.firstText = 0;
              this.secondText = 0;
            } else {
              this.firstText = this.page * this.page_size - this.page_size + 1;
              if (this.questionList.length != this.page_size) {
                this.secondText = Math.abs(
                  this.page * this.page_size -
                    this.page_size +
                    this.questionList.length
                );
              } else {
                this.secondText = this.page * this.page_size;
              }
            }
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    answerScreen(id) {
      this.$router.push("/question/" + id + "/answer");
    },
    onLimitChange() {
      this.page = 1;
      this.getFlagQuestionLists();
    },
    previousPage() {
      if (this.page > 1) {
        this.page = this.page - 1;
        this.getFlagQuestionLists();
      }
    },
    nextPage() {
      if (this.page < Math.ceil(this.totalCount / this.page_size)) {
        this.page = this.page + 1;
        this.getFlagQuestionLists();
      }
    },
    claimQuestion() {
      let loader = Vue.$loading.show();
      this.$apiService
        .claimQuestion({}, this.selectForClaim)
        .then((response) => {
          loader.hide();
          let res = response.data;

          if (response.status == 200) {
            this.$toastMessage.success(res.message);
            localStorage.setItem("claimedCount",parseInt(this.claimedCount) + parseInt(1));
            this.claimedCount = parseInt(this.claimedCount) + parseInt(1);
            if(this.claimedCount > 0){
              document.getElementById("claimedCount").innerHTML=this.claimedCount;
              document.getElementById("claimedCount").style.display = "block";
            }else{
              document.getElementById("claimedCount").style.display = "none";
            }
            this.getFlagQuestionLists();
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            loader.hide();
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
    onSearch() {
      if (this.filter.date === "8") {
        if (this.filter.start_date == undefined) {
          this.$toastMessage.error("Start Date is required");
          return false;
        }

        if (this.filter.end_date == undefined) {
          this.$toastMessage.error("End Date is required");
          return false;
        }
      }

      this.page = 1;
      this.getFlagQuestionLists();
    },
    orderByHandler(order, column) {
      if (this.filter.sort_on == column) {
        this.filter.sort_type = this.filter.sort_type == "asc" ? "desc" : "asc";
      } else {
        this.filter.sort_on = column;
        this.filter.sort_type = order;
      }
      this.getFlagQuestionLists();
    },
    selectFlagHandler() {
      if (this.selectForFlag == 3) {
        this.commentModalShow = true;
      } else {
        this.flagQuestion();
      }
    },
    commentHandler(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.flagComment == undefined || this.flagComment == "") {
        this.$toastMessage.error("Comment is required");
        return false;
      }
      this.commentModalShow = false;
      this.flagQuestion();
    },
    flagQuestion() {
      let sendData = {
        flag_status: this.selectForFlag,
        comment: this.flagComment,
      };

      this.$apiService
        .flagQuestion(sendData, this.selectForClaim)
        .then((response) => {
          let res = response.data;

          if (response.status == 200) {
            this.$toastMessage.success(res.message);
            this.getFlagQuestionLists();
            this.getSuggestionQuestion();
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
    checklistHandler() {
      this.$router.push("/guidelines");
    },
    getClaimedQuestion() {
      let params = {
        page: 1,
        page_size: 10,
        country: this.parseJSON(localStorage.getItem("selectedCountry"))["id"],
        status_type: 1,
        locale: this.parseJSON(localStorage.getItem("selectedCountry"))[
          "locale"
        ],
      };
      this.$apiService
        .getQuestion(params)
        .then((response) => {
          let res = response.data.data;
          if (response.status == 200) {
            this.totalCount = res.total;
            // set claimed count 
            localStorage.setItem("claimedCount",this.totalCount);
            this.claimedCount = localStorage.getItem("claimedCount");
            if(this.claimedCount > 0){
              document.getElementById("claimedCount").innerHTML=this.claimedCount;
              document.getElementById("claimedCount").style.display = "block";
            }else{
              document.getElementById("claimedCount").style.display = "none";
            }
          }
        })
        .catch((e) => {
         // loader.hide();
        });
    },
  },
  created() {
    this.resetData();
    this.getFlagQuestionLists();
    this.getClaimedQuestion();
  },
  mounted() {
    this.$eventBus.$on("country-change-event", () => {
      this.resetData();
      this.getFlagQuestionLists();
      this.getClaimedQuestion();
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("country-change-event");
  },
};
</script>

<style scoped>
.guidelines-btn {
  background: #53bbb4;
  color: #fff;
  padding: 2px 8px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 65%;
}
</style>
