<template>
  <header class="main-header">
    <div class="header-container mx-auto">
      <nav class="navbar navbar-expand-lg py-3">
        <router-link
          class="navbar-brand m-0 p-0"
          to="/question"
        >
          <img
            src="../assets/images/logo.png"
            width="150"
            alt="site-logo"
            class="d-md-block"
          >
          <!-- <img
            src="../assets/images/mini-logo.png"
            class="d-block d-lg-none"
            width="50"
            alt="site-logo"
        /> -->
        </router-link>
        <button
          class="navbar-toggler"
          @click="openMenu()"
        >
          <img
            src="../assets/images/menu.png"
            width="24"
            class="open-menu"
            alt=""
          >
        </button>

        <a
          class="custom-collapse"
          @click="toggleCollapse()"
        >
          <i class="fa fa-chevron-circle-left" />
        </a>

        <ul class="navbar-nav ml-auto">
          <li
            class="pr-2"
            v-if="selectedCountry != null"
          >
            <div class="lang-dropdown">
              <b-dropdown
                right
                :text="selectedCountry.name"
                class="nav-item"
              >
                <b-dropdown-item
                  class="hand-pointer"
                  v-for="(country, index) of countryList"
                  :key="index"
                  @click="selectCountry(country)"
                  :class="
                    country.id === selectedCountry.id ? 'dropdown-active' : ''
                  "
                >
                  {{ country.name }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </li>
          <li class="notification">
            <div>
              <span class="profile-wrap">
                <img
                  src="../assets/images/notification.png"
                  width="15"
                  id="notificationId"
                  alt="Notification"
                >
              </span>
              <b-dropdown
                right
                text="Notification"
                class="nav-item"
              >
                <b-dropdown-item
                  v-for="(question, index) of notificationList" 
                  :key="index"
                  :class="{'read': question.flag == true, 'unread': question.flag == false }"
                  @click="notificationScreen(question.type,question.question_id,question.id,question.flag)"
                >
                  <img
                    v-if="question.type == 1"
                    src="../assets/images/suggestion.png"
                    width="20"
                    alt="Suggested Question"
                    title="Suggested Question"
                  >
                  <img
                    v-if="question.type == 2"
                    src="../assets/images/reminder.png"
                    width="20"
                    alt="8 Hours Remind"
                    title="8 Hours Remind"
                  >
                  <img
                    v-if="question.type == 3"
                    src="../assets/images/reminder.png"
                    width="20"
                    alt="20 Hours Remind"
                    title="20 Hours Remind"
                  >
                  <img
                    v-if="question.type == 4"
                    src="../assets/images/draft.png"
                    width="20"
                    alt="Draft"
                    title="Draft"
                  >
                  <span class="contentQuestion">{{ question.question }}</span>
                  <span class="timedata">{{ question.times }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="notificationList.length > 0"
                  @click="notificationScreen('all')"
                >
                  <a
                    href="/notification"
                    id="view_all"
                  >View All</a>
                </b-dropdown-item>
                <b-dropdown-item
                  class="text-center"
                  v-if="notificationList.length == 0"
                >
                  <p>No Notification Available</p>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </li>
          <li>
            <div>
              <span class="profile-wrap">
                <img
                  :src="userAvatar"
                  width="24"
                  alt="profile"
                  id="avatarId"
                  v-if="userAvatar != null"
                >
                <img
                  :src="defaultAvatar"
                  width="24"
                  alt="profile"
                  v-if="userAvatar == null"
                >
              </span>
              <b-dropdown
                right
                :text="userName"
                class="nav-item"
              >
                <b-dropdown-item
                  @click="logout()"
                  class="hand-pointer"
                >
                  <img
                    src="../assets/images/logout.png"
                    width="20"
                    alt="logout"
                  >
                  Logout
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import Vue from "vue";

export default {
  name: "Header",
  data() {
    return {
      userName: "",
      countryList: [],
      selectedCountry: {},
      options: ["A", "B", "C"],
      value: "B",
      userAvatar: null,
      defaultAvatar: null,
      notificationList: [],
    };
  },
  watch: {
    countryList: function(newVal, oldVal) {
      if((newVal instanceof Array && newVal.length > 0)) {
        if(!this.selectedCountry || this.selectedCountry instanceof Object && !this.selectedCountry.id) {
          this.selectCountry(this.countryList[0]);
    }
      }
    },
  },
  methods: {
    openMenu() {
      document.getElementsByTagName("body")[0].classList.add("opne-menu");
    },
    toggleCollapse() {
      let bodyElement = document.getElementsByTagName("body")[0];
      if (bodyElement.classList.contains("opne-menu")) {
        bodyElement.classList.remove("opne-menu");
        return;
      }
      bodyElement.classList.add("opne-menu");
    },
    logout() {
      let loader = Vue.$loading.show();

      this.$apiService
        .logout({})
        .then((response) => {
          loader.hide();
          if (response.status == 200) {
            localStorage.clear();
            this.$toastMessage.success("Logout successfully");
            this.$router.push("/");
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    selectCountry(data) {
      this.selectedCountry = data;
      this.$apiService.updateSelectedLanguage(data.id).then((response) => {
        localStorage.setItem("selectedCountry", JSON.stringify(data));
        this.$eventBus.$emit("country-change-event", true);
      });
    },
    getProfileOnReload() {
      let loader = Vue.$loading.show();

      this.$apiService
        .getProfileOnReload()
        .then((response) => {
          loader.hide();

          let res = response.data.data;

          let countryList = res.countries.map((val) => {
            return {
              id: val.id,
              name: val.name + " - " + val.language,
              locale: val.locale,
            };
          });

          this.userName = res.name;
          this.countryList = countryList.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          this.userAvatar = res.avatar;

          let userDetails = this.parseJSON(localStorage.getItem("userDetails"));

          userDetails.name = this.userName;
          userDetails.country = this.countryList;
          userDetails.userAvatar = this.userAvatar;

          localStorage.setItem("userDetails", JSON.stringify(userDetails));

          if (
            (res.countries.length > 0 && res.currentLanguage == null) ||
            (res.countries.length == 0 && res.currentLanguage != null)
          ) {
            let selectedLang =
              countryList.length > 0
                ? countryList.find((l) => l.locale.startsWith("en")) ||
                  countryList[0]
                : null;

            this.$apiService
              .updateSelectedLanguage(
                selectedLang != null ? selectedLang.id : null
              )
              .then((response) => {
                localStorage.setItem(
                  "selectedCountry",
                  JSON.stringify(selectedLang)
                );
                this.selectedCountry = !selectedLang ? this.countryList[0] || {} : selectedLang;
                this.$eventBus.$emit("country-change-event", true);
              });
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    getNotification() {
      let params = {
        page: this.page,
        page_size: this.page_size,
        country: this.parseJSON(localStorage.getItem("selectedCountry"))["id"],
        status_type: 0,
        locale: this.parseJSON(localStorage.getItem("selectedCountry"))[
          "locale"
        ],
        userId: localStorage.getItem("userId"),
      };
     
      // if (this.filter.hasOwnProperty("sort_on")) {
      //   params.sort_on = this.filter.sort_on;
      // }

      // if (this.filter.hasOwnProperty("sort_type")) {
      //   params.sort_type = this.filter.sort_type;
      // }
      
      // pass userId for check Question
      if(localStorage.getItem("userId"))
      {
        params.userId = localStorage.getItem("userId");
      }
      params.flag = 'active';
      let loader = Vue.$loading.show();

      this.$apiService
        .getNotification(params)
        .then((response) => {
          loader.hide();
          let res = response.data.data;

          if (response.status == 200) {
            this.notificationList = res.data;
            this.totalCount = res.total;
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    notificationScreen(flag,questionId,id,unreadflag) {
      if(flag == 'all'){
        this.$router.push("/notification");
      }else{
        if(unreadflag == false){
          this.updateFlag(id);
        }
        this.$router.push("/question/" + questionId +"/answer");
        //this.$router.push("/details/" + questionId +"/"+flag);
      }
    },
    updateFlag(notificationId) {
      this.$apiService
        .notificationFlag({}, notificationId)
        .then((response) => {
          let res = response.data;
          if (response.status == 200) {
            this.$toastMessage.success(res.message);
            this.getNotification();
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
  },
  created() {
    this.getNotification();
  },
  mounted() {
    
    this.$eventBus.$on("profile-name-change-event", (Data) => {
      this.userName = Data;
    });

    this.$eventBus.$on("profile-avatar-change-event", (Data) => {
      this.userAvatar = Data;
    });

    this.selectedCountry = this.parseJSON(
      localStorage.getItem("selectedCountry")
    );

    // This should run as long as there are credentials not only if there are credentials and a token
    if (localStorage.getItem("accessToken")) {
      this.getProfileOnReload();
    }

    this.defaultAvatar = this.$apiService.getDefaltAvatar();

    let avatarId = document.getElementById("avatarId");

    if (avatarId != null) {
      avatarId.addEventListener("error", function () {
        avatarId.src = this.defaultAvatar;
      });
    }
    this.getNotification();
  },
  beforeDestroy() {
    this.$eventBus.$off("country-change-event");
  },
};
</script>

<style>
.countryname {
  padding: 5px 15px !important;
}
#notificationId{
  filter: brightness(0) invert(1);
}
.notification .unread .dropdown-item .contentQuestion {
  font-size: 14px;
  font-weight: 500;
  color: #5bbcb4;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 67%;
  vertical-align: middle;
}

.notification .unread {
  background: #f4fafa;
  border-bottom: 1px solid #fff;
}

.notification .unread img {
  display: inline-block;
  width: 30px;
  height: 30px;
  -o-object-fit: contain;
  object-fit: contain;
  background: #dcf1f0;
  padding: 5px;
  border-radius: 10px;
  margin-right: 10px;
}

.notification .read img {
display: inline-block;
  width: 30px;
  height: 30px;
  -o-object-fit: contain;
  object-fit: contain;
  background: #e9eaec;
  padding: 5px;
  border-radius: 10px;
  margin-right: 10px;
}
header.main-header .notification .nav-item .dropdown-menu{
  max-height: 350px !important;
}
header.main-header .notification .nav-item .dropdown-menu .timedata {
  text-align: right;
  font-weight: 400;
  font-size: 12px;
  margin-left: 10px;
  vertical-align: middle;
  font-style: italic;
  color: #495057;
  flex: auto;
}
header.main-header .notification .nav-item .dropdown-menu {
  min-width: 21rem !important;
}
.notification .unread .dropdown-item {
  display: flex;
  align-items: center;
}
header.main-header .notification .nav-item .dropdown-menu li:last-child {
  text-align: center;
  border: 1px solid #53bbb4;
  color: #fff;
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;
  flex: 0 0 auto;
  max-width: max-content;
  border-radius: 5px;
  margin: 10px auto;
}
header.main-header .notification .nav-item .dropdown-menu li:last-child a {
  color: #53bbb4;
}
</style>
