import axios from "axios";

import DefaultImage from "../assets/images/profile-picture.png";

export default class APIService {
	getDefaltAvatar() {
		return DefaultImage;
	}

	validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(
			String(email)
				.trim()
				.toLowerCase()
		);
	}

	login(data) {
		return axios.post("login", data);
	}

	forgotPassword(data) {
		return axios.post("forgot-password", data);
	}

	forgotTokenCheck(token) {
		return axios.get("password/validate-token/" + token);
	}

	resetPassword(data) {
		return axios.post("reset-password", data);
	}

	logout(data) {
		return axios.post("logout", data, {
			headers: this.getAPIHeader(),
		});
	}

	getAPIHeader() {
		let access_token = localStorage.getItem("accessToken");
		return {
			Authorization: `Bearer ${access_token}`,
		};
	}

	getProfileImageHeader() {
		let access_token = localStorage.getItem("accessToken");
		return {
			Authorization: `Bearer ${access_token}`,
			"Content-Type":
				"multipart/form-data; charset=utf-8; boundary=" +
				Math.random()
					.toString()
					.substr(2),
		};
	}

	getQuestion(params) {
		return axios.get("question-lists", {
			headers: this.getAPIHeader(),
			params,
		});
	}

	getCategory(params) {
		return axios.get("categories-list", {
			headers: this.getAPIHeader(),
			params,
		});
	}

	claimQuestion(body, Id) {
		return axios.put("claimed-question/" + Id, body, {
			headers: this.getAPIHeader(),
		});
	}

	unclaimQuestion(body, Id) {
		return axios.put("unclaimed-question/" + Id, body, {
			headers: this.getAPIHeader(),
		});
	}

	flagQuestion(body, Id) {
		return axios.post("flag/" + Id, body, {
			headers: this.getAPIHeader(),
		});
	}

	getProfile() {
		return axios.get("get-profile", {
			headers: this.getAPIHeader(),
		});
	}

	updateProfile(body) {
		return axios.put("update-profile", body, {
			headers: this.getAPIHeader(),
		});
	}

	submitAnswer(body, Id) {
		return axios.post("answer-question/" + Id, body, {
			headers: this.getAPIHeader(),
		});
	}

	getQuestionById(questionId, params) {
		return axios.get("get-question-detail/" + questionId, {
			headers: this.getAPIHeader(),
			params,
		});
	}

	getProfileOnReload() {
		return axios.get("profile-reload", {
			headers: this.getAPIHeader(),
		});
	}

	updateProfileImage(body) {
		return axios.post("upload-media", body, {
			headers: this.getProfileImageHeader(),
		});
	}

	updateSelectedLanguage(countryId) {
		return axios.put("save-language/" + countryId, {}, {
			headers: this.getAPIHeader(),
		});
	}

	getAllExpertise() {
		return axios.get("expertise-list", {
			headers: this.getAPIHeader(),
		});
	}

	getDoctorExpertise(doctorId) {
		return axios.get("doctor-expertise-list?doctorId=" + doctorId, {
			headers: this.getAPIHeader(),
		});
	}

	removeDoctorExpertise(id) {
		return axios.delete("remove-expertise?id=" + id, {
			headers: this.getAPIHeader(),
		});
	}

	addDoctorExpertise(data) {
		return axios.post("add-expertise" , data, {
			headers: this.getAPIHeader(),
		});
	}

	getNotification(params) {
		return axios.get("notification", {
			headers: this.getAPIHeader(),
			params,
		});
	}

	notificationFlag(body, Id) {
		return axios.put("notification-flag/" + Id, body, {
			headers: this.getAPIHeader(),
		});
	}

	getNotificationById(questionId, params) {
		return axios.get("get-notification-detail/" + questionId, {
			headers: this.getAPIHeader(),
			params,
		});
	}

	getSuggestion(params) {
		return axios.get("suggestion-lists", {
			headers: this.getAPIHeader(),
			params,
		});
	}
	getFlagQuestionLists(params) {
		return axios.get("flagquestion-lists", {
			headers: this.getAPIHeader(),
			params,
		});
	}
}
