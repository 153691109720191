<template>
  <div class="container">
    <div class="user-login-wrap row p-3">
      <div class="card-wrap col-md-5 mx-auto add-question-wrap">
        <a class="navbar-brand text-center d-block mb-5 p-0"><img
          src="../assets/images/logo.png"
          width="150"
          alt="site-logo"
        ></a>
        <div
          v-if="isExpired != null && isExpired"
          class="text-center"
        >
          <p class="link-expire">
            This link has been expired.
          </p>
        </div>
        <form
          action=""
          v-if="isExpired != null && !isExpired"
        >
          <div class="form-group mb-4">
            <label for="">Password</label>
            <input
              type="password"
              placeholder="Password"
              class="form-control"
              v-model="newUser.password"
            >
          </div>
          <div class="form-group mb-4">
            <label for="">Confirm Password</label>
            <input
              type="password"
              placeholder="Confirm Password"
              class="form-control"
              v-model="newUser.confirm_password"
            >
          </div>
          <div class="form-group">
            <a
              class="drbtn btn  btn-outline-primary"
              @click="onSubmit()"
              style="width:100%"
            >Reset Password</a>
          </div>
        </form>
        <router-link
          to="/"
          class="forgotpass"
        >
          Log In
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Login",
  data() {
    return {
      newUser: {},
      isExpired: null,
    };
  },
  methods: {
    onSubmit() {
      if (this.newUser.password == undefined || this.newUser.password == "") {
        this.$toastMessage.error("Password is required");
        return false;
      }

      if (
        this.newUser.confirm_password == undefined ||
        this.newUser.confirm_password == ""
      ) {
        this.$toastMessage.error("Confirm password is required");
        return false;
      }

      if (this.newUser.password != this.newUser.confirm_password) {
        this.$toastMessage.error("Confirm password and password must match");
        return false;
      }

      let loader = Vue.$loading.show();

      let sendObj = {
        password: this.newUser.password,
        token: this.$route.params.token,
      };

      this.$apiService
        .resetPassword(sendObj)
        .then((response) => {
          loader.hide();
          let res = response.data;

          if (response.status == 200) {
            this.$toastMessage.success(res.message);
            this.$router.push("/");
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    checkExpiry() {
      let token = this.$route.params.token;
      let loader = Vue.$loading.show();

      this.$apiService
        .forgotTokenCheck(token)
        .then((response) => {
          loader.hide();
          if (response.status == 200) {
            this.isExpired = false;
          }
        })
        .catch((e) => {
          loader.hide();
          this.isExpired = true;
        });
    },
  },
  created() {
    localStorage.clear();
    this.checkExpiry();
  },
};
</script>
